import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout/Layout'

let helmut = {
  title: 'Treeland Trails Photos',
  description: [''].join('\n'),
  keywords: [''].join('\n'),
}

const photoNavLinkData = require('../content/photoNavLinkData.js')

const Photos = () => {
  let linkList = []
  Object.keys(photoNavLinkData).forEach((relativeDirectory) => {
    let photoData = photoNavLinkData[relativeDirectory]
    let nameList = photoData['albumList'] || []
    let prefix = photoData['title'] ? photoData['title'] + ': ' : ''
    nameList.forEach((name) => {
      linkList.push({
        name: prefix + name,
        path: '/' + relativeDirectory + '/' + name,
      })
    })
  })

  return (
    <Layout
      helmutTitle={helmut.title}
      helmutDescription={helmut.description}
      helmutKeywords={helmut.keywords}
    >
      <ul>
        {linkList.map((node, index) => (
          <li>
            <Link to={node.path}>{node.name}</Link>
          </li>
        ))}
      </ul>
    </Layout>
  )
}

export default Photos
